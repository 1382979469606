<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back" :readonly="$authorised('con/registration/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body>

		<app-content-box :readonly="$authorised('con/registration/access', 'read')">

			<app-input-text label="Name" v-model="model.name" :validation="$v.model.name" placeholder="Enter name..." notes="For your reference only, not seen by users." />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/registration/access', 'read')" title="Content" icon="design" v-if="model.type === $constants.block.type.text">

			<app-input-content v-model="model.text.content" :validation="$v.model.text.content" placeholder="Enter content..." />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/registration/access', 'read')" title="Content" icon="design" v-if="model.type === $constants.block.type.banner">

			<app-input-image v-model="model.banner.image" :validation="$v.model.banner.image" label="Image" />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/registration/access', 'read')" title="Content" icon="design" v-if="model.type === $constants.block.type.gallery" :not-padded="true">

			<app-input-gallery v-model="model.gallery.images" :validation="$v.model.gallery.images" placeholder="Upload image" />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/registration/access', 'read')" title="Content" icon="design" v-if="model.type === $constants.block.type.video">

			<app-input-text label="URL" v-model="model.video.source" :validation="$v.model.video.source" placeholder="Enter URL (e.g. youtube)" />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/registration/access', 'read')" title="Content" icon="design" v-if="model.type === $constants.block.type.map">

			<app-input-text label="URL" v-model="model.map.url" :validation="$v.model.map.url" placeholder="Enter URL (e.g. google maps)" />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/registration/access', 'read')" title="Content" icon="design" v-if="model.type === $constants.block.type.addons">

			<app-input-text label="Title" v-model="model.addons.title" :validation="$v.model.addons.title" placeholder="Enter title..." />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/registration/access', 'read')" title="Items" icon="addon" v-if="model.type === $constants.block.type.addons" :not-padded="true">

			<app-input-items v-model="model.addons.list" :validation="$v.model.addons.list" placeholder="Add item" api="convention/registration/item" />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/registration/access', 'read')" title="Items" icon="addon" v-if="model.type === $constants.block.type.items" :not-padded="true">

			<app-input-items v-model="model.items.list" :validation="$v.model.items.list" placeholder="Add item" api="convention/registration/item" />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/registration/access', 'read')" title="Content" icon="design" v-if="model.type === $constants.block.type.registration">

			<app-input-toggle label="Badges" :column="true" :multiple="true" :asString="true" v-model="model.registration.badges" :validation="$v.model.registration.badges" :options="badgeOptions" />

			<app-input-inputs label="Inputs" v-model="model.registration.inputs" :validation="$v.model.registration.inputs" placeholder="No inputs added." />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/registration/access', 'read')" title="Content" icon="design" v-if="model.type === $constants.block.type.thirdparty">

			<app-input-inputs label="Inputs" v-model="model.thirdparty.inputs" :validation="$v.model.thirdparty.inputs" placeholder="No inputs added." />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/registration/access', 'read')" title="Content" icon="design" v-if="model.type === $constants.block.type.capture">

			<app-input-inputs label="Inputs" v-model="model.capture.inputs" :validation="$v.model.capture.inputs" placeholder="No inputs added." />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { url } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/registration/access', 'read'),
			references: {
				badges: []
			},
			model: {
				id: false,
				type: false,
				text: {
					content: ''
				},
				banner: {
					image: ''
				},
				gallery: {
					images: []
				},
				video: {
					source: ''
				},
				map: {
					url: ''
				},
				addons: {
					title: '',
					list: []
				},
				registration: {
					badges: [],
					inputs: []
				},
				thirdparty: {
					inputs: []
				},
				capture: {
					inputs: []
				},
				items: {
					list: []
				}
			}
		}

	},

	validations: {
		model: {
			text: {},
			banner: {},
			gallery: {},
			addons: {},
			video: {
				source: {
					url
				}
			},
			map: {
				url: {
					url
				}
			},
			items: {},
			registration: {},
			thirdparty: {},
			capture: {}
		}
	},

	computed: {

		badgeOptions: function() {

			var options = []

			this.$_.each(this.references.badges, function(badge) {
				
				options.push({
					value: badge.id,
					text: badge.name
				})

			})

			return options

		}

	}

}

</script>

<style scoped>

</style>